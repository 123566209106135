import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloProvider } from '@vue/apollo-option';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core/core.cjs';
import { setContext } from '@apollo/client/link/context/context.cjs';
import store from '../store';
import apolloConfig from '../config/apollo-config';
import { getAccessToken } from './iam';

// Install the vue plugin
// Vue.use(VueApollo);

// TODO createUploadLink -
/* const httpLink = createUploadLink({
  uri: httpEndpoint,
  ...httpLinkOptions,
}) */

// Get GraphQL results over a network using HTTP fetch
const httpLink = createHttpLink({
  uri: apolloConfig.url,
});

// HTTP Auth header injection
const authLink = setContext(async (_, { headers }) => {
  try {
    let authorizationHeader = {};
    const user = await store.state.iam.user;
    // console.debug('user', user);
    if (user) {
      const token = await getAccessToken();
      // console.debug('token', token);
      authorizationHeader = token ? { authorization: `Bearer ${token}` } : {};
    }
    return {
      headers: {
        ...headers,
        ...authorizationHeader,
      },
    };
  } catch (error) {
    Vue.prototype.$utils.alertError(error);
    throw error;
  }
});

// Concat all the http link parts
const link = authLink.concat(httpLink);

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  resolvers: {
    Query: {
      testLocalQuery() {
        return true;
      },
    },
  },
});

// Create the apollo provider
const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export default apolloProvider;
