import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import { checkUser } from './lib/iam';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ './views/Users.vue'),
      meta: {
        requiresAuth: true,
      },
    },
  ],
  linkExactActiveClass: 'is-active', // https://router.vuejs.org/api/#exact-active-class
});

router.beforeEach((to, from, next) => {
  const validUser = checkUser();
  if (to.matched.some((record) => record.meta.requiresAuth) && !validUser) {
    next('/');
  } else {
    next();
  }
});


export default router;
