import { createApp } from 'vue';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import App from './App.vue';
import router from './router';
import store from './store';
import apolloProvider from './lib/apollo';

const app = createApp(App);
app.use(router).use(store).use(apolloProvider).mount('#app');
