const clientId = 'd8a99239-6dd2-4c96-b42b-84e72dff64d5'; // graph-msal2-ist-platform-admin
const aadAuthority = 'login.microsoftonline.com';
const publisherDomain = 'istplatformb2c.onmicrosoft.com';

const config = {
  auth: {
    clientId,
    authority: `https://${aadAuthority}/${publisherDomain}`,
    redirectUri: `${window.location.origin}`,
    navigateToRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          // eslint-disable-next-line no-useless-return
          return;
        }
        // console.log(message); // uncomment for MSAL debugging output
      },
      piiLoggingEnabled: false,
    },
  },
  scopes: [
    'openid',
    'User.Readwrite.All',
  ],
};

export default config;
