// TODO: rename iam-store.js
export default {
  state: {
    user: null,
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    save({ commit }, user) {
      commit('setUser', user);
    },
  },
  modules: {
  },
  namespaced: true,
};
