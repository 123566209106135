<template>
  <div id="app">
    <div class="main-flex">
        <nav id="NavigationBar" class="navbar is-dark with-shadow">
        <div class="container">
          <div class="navbar-brand">
            <a
              href="https://www.insilicotrials.com"
              class="navbar-item navbar-logo"
            >
              <img src="./assets/ist_logo_white.png">
            </a>
            <a
              role="button"
              class="navbar-burger burger"
              :class="{ 'is-active': isBurger }"
              aria-label="menu"
              aria-expanded="false"
              @click="toggleBurger"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            class="navbar-menu"
            :class="{ 'is-active': isBurger }"
          >
            <div class="navbar-end">
              <router-link class="navbar-item" to="/">Home</router-link>
              <router-link class="navbar-item" to="/users" v-if="user">Users</router-link>
              <span class="navbar-item">
                <button class="button is-info" @click="login" v-if="!user">
                  <i class="fas fa-sign-in-alt" size="is-small"></i>
                  <span class="ml-2">Login</span>
                </button>
                <button class="button is-white is-outlined" @click="logout" v-if="user">
                  <i class="fas fa-sign-out-alt" size="is-small"></i>
                  <span class="ml-2">Logout</span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </nav>
      <div class="section">
        <div class="container ist-container">
          <router-view/>
        </div>
      </div>
    </div>
    <footer class="footer bottom-flex has-background-white-ter">
      <div class="container has-text-centered">
        <p>
          All rights reserved.
          <br>
          ©2022 by
          <a href="https://www.insilicotrials.com">InSilicoTrials Technologies</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { login, logout } from '@/lib/iam';

export default {
  name: 'app',
  data() {
    return {
      isBurger: false,
    };
  },
  computed: {
    ...mapState('iam', {
      user: (state) => state.user,
    }),
  },
  methods: {
    login,
    logout,
    toggleBurger() {
      this.isBurger = !this.isBurger;
    },
  },
};
</script>


<style lang="scss">
/* Bulma customisation */

$body-size: 18px;
$primary: #ffddc8; // Peach
$link: #3703ff; // Deep Blue
$info: #ffddc8; // Peach
$success: #1b998b;
$warning: #e3b505;
$danger: #ee4266;
$light: #ffffff; // White
$dark: #3703ff; // Deep Blue
// Table
$table-head-background-color: #a7e5ff; // Crystal
$table-row-hover-background-color: #e7f8ff; // Light Crystal
$table-striped-row-even-hover-background-color: #e7f8ff; // Light Crystal
// Tabs
$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;
$tabs-toggle-link-active-color: hsl(0, 0%, 29%); // Gray Dark
// Steps
$steps-completed-color: $dark;
$steps-active-color: $dark;

@import "~bulma/bulma.sass";

/* CSS for sticky footer from https://css-tricks.com/couple-takes-sticky-footer/ */

html {
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-flex {
  flex: 1 0 auto;
  background-color: #f4f4f4; // Dust
}

.bottom-flex {
  flex-shrink: 0;
}

div.ist-container {
  background-color: white;
  padding: 1.5em;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 0px;
  box-shadow: 0px 0px 1em hsl(0, 0%, 86%);
}

#NavigationBar {
  border-radius: 0;
  border-bottom: 0px;
  height: 4.5em;
}
.with-shadow {
  box-shadow: 0px 0px 1em gray;
}
.navbar-logo > img {
  max-height: 2em !important;
}
</style>
